import React, { useRef, useState } from 'react';
import * as THREE from 'three';
import { useFrame, Canvas, useThree, extend } from '@react-three/fiber';
import { Stats } from '@react-three/drei';

extend({ Stats });


//shader function
const SomeShaderFunc = {
    uniforms: {
        iResolution: { value: new THREE.Vector2() },
        iTime: { value: 0 },
        cursorOffset: { value: new THREE.Vector2(0, 0) },
    },
    vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
    fragmentShader: `
    varying vec2 vUv;
    uniform float iTime;
    uniform vec2 cursorOffset; // Add cursor offset uniform

    void main() {
  
      
      vec2 p = -1.0 + 2.0 * (vUv*1.5 - cursorOffset);

      float a = iTime * 75.0;

      float d, e, f, g = 1.0 / 40.0, h, i, r, q;

      e = 400.0 * (p.x * 0.5 + 0.5);
      f = 400.0 * (p.y * 0.5 + 0.5);
      i = 200.0 + sin(e * g + a / 150.0) * 20.0;
      d = 200.0 + cos(f * g / 2.0) * 18.0 + cos(e * g) * 7.0;
      r = sqrt(pow(abs(i - e), 2.0) + pow(abs(d - f), 2.0));
      q = f / r;
      e = (r * cos(q)) - a / 2.0;
      f = (r * sin(q)) - a / 2.0;
      d = sin(e * g) * 176.0 + sin(e * g) * 164.0 + r;
      h = ((f + d) + a / 2.0) * g;
      i = cos(h + r * p.x / 1.3) * (e + e + a) + cos(q * g * 6.0) * (r + h / 3.0);
      h = sin(f * g) * 144.0 - sin(e * g) * 212.0 * p.x;
      h = (h + (f - e) * q + sin(r - (a + h) / 7.0) * 10.0 + i / 6.0) * g;
      i += cos(h * 2.3 * sin(a / 350.0 - q)) * 184.0 * sin(q - (r * 4.3 + a / 12.0) * g) + tan(r * g + h) * 184.0 * cos(r * g + h);
      i = mod(i / 5.6, 256.0) / 64.0;
      if (i < 0.0) i += 4.0;
      if (i >= 2.0) i = 4.0 - i;
      d = r / 350.0;
      d += sin(d * d * 15.0) * 0.2;
      f = (sin(a * g) + 1.0) / 2.0;
      
      gl_FragColor = vec4(
      vec3(i * i / 1.6, i / 2.0 + d / 13.0, i) * d * p.x + 
      vec3(i / 1.3 + d / 8.0, i / 2.0 + d / 18.0, i) * d * (0.9 - p.x), 1.5);
    }
  `,
};


// Render code
const Background = () => {
  const materialRef = useRef();
  const { viewport } = useThree();
  const [cursorOffset, setCursorOffset] = useState({ x: 0, y: 0 });

  // useFrame hook inside the Background component
  useFrame(({ clock, mouse }) => {
    const time = clock.getElapsedTime();
    materialRef.current.uniforms.iTime.value = time;
    materialRef.current.uniforms.iResolution.value.copy(new THREE.Vector2(viewport.width, viewport.height));
    setCursorOffset({ x: mouse.x, y: mouse.y });
    materialRef.current.uniforms.cursorOffset.value.copy(cursorOffset);
  });

  return (
    <mesh>
      <planeGeometry args={[viewport.width, viewport.height]} />
      <shaderMaterial ref={materialRef} args={[SomeShaderFunc]} />
    </mesh>
  );
};

const App = () => {

  return (
    <div>
      {/* FPS display */}
      <Stats
        showPanel={0}
        style={{
          zIndex: 1,
        }}
      />
      {/* Canvas component */}
      <Canvas style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}>
        <Background />
      </Canvas>
    </div>
  );
};


export default App;