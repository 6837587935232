const Footer = () => {

    return (
        <div style={{
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'center',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            alignSelf: 'center',
            height: '30px',
            alignContent: 'center',
            
        }}>
            <span style={{ fontWeight: 'normal', lineHeight: '30px'}}>Website by Karthik Krishna.</span>
        </div>
    )
}

export default Footer 