import React from "react";
import Background from "./pages/Background";
import Footer from "./pages/Footer";

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}>
      <Background style={{ flex: 1 }} />
      <Footer style={{ width: "100%" }} />
    </div>
  );
}

export default App;
